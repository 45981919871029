<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="grid-content bg-purple">
            <div style="display: inline-block" class="title_h">
              <h5>{{ $t("message.new_owner_clinic") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                size="medium"
                :loading="save_loading"
                :disabled="save_loading ? true : false"
                type="success"
                @click="saveData"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :sm="10">
          <el-form-item :label="columns.full_logo.title" prop="image">
            <el-upload
              class="upload-demo w-100 m-d-flex"
              action="/"
              ref="upload"
              :limit="1"
              :on-change="updateFullLogo"
              :on-remove="removeFullLogo"
              list-type="picture-card"
              :file-list="FullLogo"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :sm="10">
          <el-form-item :label="columns.half_logo.title" prop="image">
            <el-upload
              class="upload-demo w-100 m-d-flex"
              action="/"
              ref="upload"
              :limit="1"
              :on-change="updateHalfLogo"
              :on-remove="removeHalfLogo"
              list-type="picture-card"
              :file-list="HalfLogo"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <div style="display: inline-block" class="title_h">
        <h5>{{ $t("message.phone_numbers") }}</h5>
      </div>
      <hr />
      <el-row :gutter="20">
        <el-radio-group v-model="form.is_footer_image" style="width: 100%">
          <el-col :sm="12">
            <el-radio :label="1" size="large">
              {{ $t("message.footer_image") }}
            </el-radio>
            <el-form-item :label="columns.footer_image.title" prop="image">
              <el-upload
                class="upload-demo w-100 m-d-flex"
                action="/"
                ref="upload"
                :limit="1"
                :on-change="updateFooterImage"
                :on-remove="removeFooterImage"
                list-type="picture-card"
                :file-list="FooterImage"
                :auto-upload="false"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-radio :label="0" size="large">
              {{ $t("message.footer_text") }}
            </el-radio>
            <el-form-item>
              <el-input
                v-model="form.footer_text"
                :rows="2"
                type="textarea"
                placeholder="Please input"
              />
            </el-form-item>
          </el-col>
        </el-radio-group>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  name: "createForm",
  data() {
    return {
      save_loading: false,
      FullLogo: [],
      HalfLogo: [],
      FooterImage: [],
    };
  },
  computed: {
    ...mapGetters({
      columns: "ownerClinic/columns",
      rules: "ownerClinic/rules",
    }),
  },
  methods: {
    ...mapActions({
      save: "ownerClinic/storeConclusionLogo",
      update: "ownerClinic/update",
      remove: "ownerClinic/destroy",
    }),
    removeFullLogo(file) {
      this.FullLogo = [];
    },
    removeHalfLogo(file) {
      this.HalfLogo = [];
    },
    removeFooterImage(file) {
      this.FooterImage = [];
    },

    updateFullLogo(file) {
      this.FullLogo = [];
      this.FullLogo.push({ url: file.url, raw: file.raw });
    },
    updateHalfLogo(file) {
      this.HalfLogo = [];
      this.HalfLogo.push({ url: file.url, raw: file.raw });
    },
    updateFooterImage(file) {
      this.FooterImage = [];
      this.FooterImage.push({ url: file.url, raw: file.raw });
    },

    saveData() {
      this.save_loading = true;

      let form = new FormData();

      for (const key in this.FullLogo) {
        if (this.FullLogo.hasOwnProperty(key)) {
          const element = this.FullLogo[key];
          form.append(`full_logo`, element.raw);
        }
      }
      for (const key in this.HalfLogo) {
        if (this.HalfLogo.hasOwnProperty(key)) {
          const element = this.HalfLogo[key];
          form.append(`half_logo`, element.raw);
        }
      }
      for (const key in this.FooterImage) {
        if (this.FooterImage.hasOwnProperty(key)) {
          const element = this.FooterImage[key];
          form.append(`footer_image`, element.raw);
        }
      }

      for (var key in this.form) {
        if (this.form[key] != null) {
          form.append(key, this.form[key]);
        } else {
          form.append(key, "");
        }
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.save(form)
            .then((res) => {
              this.form = {};
              this.save_loading = false;
              this.resetForm("form");
              this.$parent.$parent.listChanged();
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.FullLogo = [];
              this.HalfLogo = [];
              this.FooterImage = [];
            })
            .catch((err) => {
              this.form = {};
              this.save_loading = false;
              this.resetForm("form");
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
<style lang="scss">
.naw_Editor {
  .mce-panel {
    border: 0 solid #c5c5c55e;
  }
}
.block_Editor_Naw {
  padding: 20px;
}
</style>